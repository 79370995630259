import {
  v1,
  v3,
  v4,
  v5,
  validate
} from 'uuid'

document.addEventListener('DOMContentLoaded', () => {

  // Hide error badge closest to this input element
  function hideErrorBadge(element) {
    let errorBadge = element.previousElementSibling.children[1] || null
    if (errorBadge) {
      errorBadge.classList.add('invisible')
    }
  }

  // Show error badge closest to this input element
  function showErrorBadge(element) {
    let errorBadge = element.previousElementSibling.children[1] || null
    if (errorBadge) {
      errorBadge.classList.remove('invisible')
    }
  }

  // Modify UI to show if namespace was invalid
  function validateNamespace(element) {
    if (!element.value || !validate(element.value)) {
      // missing required parameters
      element.classList.add('input-error')
      showErrorBadge(element)
      return false
    }
    return true
  }

  // Remove error border and badge from inputs on change
  document.querySelectorAll('input').forEach((el) => {
    el.addEventListener('input', (evt) => {
      evt.target.classList.remove('input-error')
      hideErrorBadge(evt.target)
    })
  })

  document.querySelectorAll('button.tab').forEach((el) => {

    // Tab switching on click
    el.addEventListener('click', () => {
      // Deactivate all tabs
      document.querySelectorAll('.tab-active').forEach((el) => {
        el.classList.remove('tab-active')
      })
      // Activate the clicked tab
      el.classList.add('tab-active')

      // Deactivate all panels
      document.querySelectorAll('.card[data-action]').forEach((el) => {
        el.classList.add('hidden')
        el.classList.remove('active')
      })
      // Activate the associated panel
      let actionName = el.attributes.getNamedItem('data-target').value
      document.querySelector(`.card[data-action="${actionName}"]`).classList.add('active')
      document.querySelector(`.card[data-action="${actionName}"]`).classList.remove('hidden')

      // Clear output box on tab switch
      document.querySelector('div.generator-output').textContent = ''
    })

  })

  // UUID generator on click
  document.querySelector('#generate').addEventListener('click', () => {
    let selectedVersion = document.querySelector('.card.active').attributes.getNamedItem('data-action').value
    let output = ''

    switch (selectedVersion) {
      case 'v1': {
        let amount = document.querySelector('div[data-action="v1"] input[type="number"]').value
        for (let i = 0; i < amount; i++) {
          if (i !== 0) {
            output = `${output}\n`
          }
          let uuid = v1()
          output = `${output}${uuid}`
        }
        break
      }
      case 'v3': {
        let nameEl = document.querySelector('div[data-action="v3"] input[name="name"]')
        let namespaceEl = document.querySelector('div[data-action="v3"] input[name="namespace"]')
        if (!validateNamespace(namespaceEl)) {
          break
        }
        let uuid = v3(
          nameEl.value,
          namespaceEl.value
        )
        output = `${uuid}`
        break
      }
      case 'v4': {
        let amount = document.querySelector('div[data-action="v4"] input[type="number"]').value
        for (let i = 0; i < amount; i++) {
          if (i !== 0) {
            output = `${output}\n`
          }
          let uuid = v4()
          output = `${output}${uuid}`
        }
        break
      }
      case 'v5': {
        let nameEl = document.querySelector('div[data-action="v5"] input[name="name"]')
        let namespaceEl = document.querySelector('div[data-action="v5"] input[name="namespace"]')
        if (!validateNamespace(namespaceEl)) {
          break
        }
        let uuid = v5(
          nameEl.value,
          namespaceEl.value
        )
        output = `${uuid}`
        break
      }
      default:
        console.error('Unknown UUID version')
        break
    }
  
    // Insert into page
    document.querySelector('.generator-output').textContent = output

    // Copy to clipboard
    document.querySelector('button[name="copy-to-clipboard"]').addEventListener('click', () => {
      try {
        navigator.clipboard.writeText(document.querySelector('div.generator-output').textContent)
      } catch (err) {
        console.error('Unable to save to clipboard')
        console.error(err)
      }
    })
  })

})